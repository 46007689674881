
body {
    --sb-track-color: #232E33;
    --sb-thumb-color: #6BAF8D;
    --sb-size: 0px;
  
    scrollbar-color: var(--sb-thumb-color) 
                     var(--sb-track-color);
  }
  
  body::-webkit-scrollbar {
    width: var(--sb-size) 
  }
  
  body::-webkit-scrollbar-track {
    background: var(--sb-track-color);
    border-radius: 1px;
  }
  
  body::-webkit-scrollbar-thumb {
    background: var(--sb-thumb-color);
    border-radius: 1px;
    
    }

    /* Add hover styles for your custom button class */
.custom-button {
  background-color: dark;
  color: white;
  transition: background-color 0.3s ease-in-out;
}

.custom-button:hover {
  background-color: red;
}

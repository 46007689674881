
/*==============================
Vertical Slider Area 
=================================*/


.vertical-bg-1{
    background-image: url(../../img/slider/vertical-slide/vertical-slide-1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}


.vertical-bg-2{
    background-image: url(../../img/slider/vertical-slide/vertical-slide-2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}


.vertical-bg-3{
    background-image: url(../../img/slider/vertical-slide/vertical-slide-3.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}


.vertical-bg-4{
    background-image: url(../../img/slider/vertical-slide/vertical-slide-4.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.vertical-bg-5{
    background-image: url(../../img/slider/vertical-slide/vertical-slide-5.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}


.bg_image--49{
    background-repeat: no-repeat;
    background-position: top 73px center;
    background-size: initial;
}
.vertical-what-do-area{
    padding-top: 167px !important;
    padding-bottom: 138px !important;
    margin-left: -15px;
    margin-right: -15px;
    position: relative;
    box-sizing: border-box;
    width: 1803px;
    padding-left: 301.5px;
    padding-right: 301.5px;
    
    @media #{$laptop-device} {
        width: auto;
        padding-left: 100px;
        padding-right: 100px;
    }

    @media #{$lg-layout} {
        width: auto;
        padding-left: 100px;
        padding-right: 100px;
    }

    @media #{$md-layout} {
        width: auto;
        padding-left: 100px;
        padding-right: 100px;
        padding-top: 80px !important;
        padding-bottom: 80px !important;
    }

    @media #{$sm-layout} {
        width: auto;
        padding-left: 100px;
        padding-right: 100px;
        padding-top: 80px !important;
        padding-bottom: 80px !important;
    }

    @media #{$large-mobile} {
        width: auto;
        padding-left: 50px;
        padding-right: 50px;
    }


    
}



/*===============================
    Vertivcal Slider Inner
=====================================*/

.single-vertical-slide{
    &.fullscreen{
        
        @media #{$sm-layout} {
            height: auto;
            min-height: auto;
            padding: 150px 0;
        }

        @media #{$md-layout} {
            height: auto;
            min-height: auto;
            padding: 150px 0;
        }
    }
    .slide-inner{
        .info{
            padding: 30px 50px 66px;
            h2{
                &.heading{
                    font-size: 48px;
                    line-height: 1.4;
                    margin-bottom: 57px;
                    font-weight: 600;
                    a{
                        color: #fff;
                    }
                }
            }
            .category{
                font-size: 14px;
                color: #fff;
            }
        }
    }
}







/*===============================
Creative People Area 
=====================================*/


.creative-people-inner{
    h2{
        &.heading{
            display: inline-block;
            padding: 2px 20px;
            background: #fff;
            font-size: 50px;
            line-height: 1.42;
            
            @media #{$md-layout} {
                font-size: 45px;
            }

            @media #{$sm-layout} {
                font-size: 32px;
            }
        }
    }
}















